import androidSvg from '../svgs/android.svg'
import caminhaoSvg from '../svgs/caminhao.svg'
import disneySvg from '../svgs/disney.svg'
import estrelaSvg from '../svgs/estrela.svg'
import gasolinaSvg from '../svgs/gasolina.svg'
import motoSvg from '../svgs/moto.svg'

export const Items = [
  {name: 'android', icon: androidSvg},
  {name: 'caminhao', icon: caminhaoSvg},
  {name: 'disney', icon: disneySvg},
  {name: 'estrela', icon: estrelaSvg},
  {name: 'gasolina', icon: gasolinaSvg},
  {name: 'moto', icon: motoSvg}
]